const addScript = () => {
  const script = document.createElement("script");
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-T1XN69N3DP";
  script.async = true;
  document.body.appendChild(script);
}

export const onClientEntry = () => {
  addScript()
  window.dataLayer = window.dataLayer || [];
  function gtag() { window.dataLayer.push(arguments); }

  gtag('js', new Date());
  gtag('config', 'G-T1XN69N3DP');
}
