exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-apple-pay-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/apple-pay/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-apple-pay-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-authentication-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/authentication/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-authentication-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-bulk-charge-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/bulk-charge/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-bulk-charge-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-charge-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/charge/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-charge-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-customer-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/customer/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-customer-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-dedicated-virtual-account-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/dedicated-virtual-account/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-dedicated-virtual-account-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-dispute-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/dispute/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-dispute-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-errors-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/errors/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-errors-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-integration-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/integration/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-integration-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-miscellaneous-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/miscellaneous/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-miscellaneous-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-page-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/page/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-page-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-payment-request-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/payment-request/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-payment-request-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-plan-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/plan/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-plan-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-product-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/product/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-product-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-refund-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/refund/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-refund-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-settlement-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/settlement/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-settlement-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-split-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/split/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-split-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-subaccount-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/subaccount/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-subaccount-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-subscription-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/subscription/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-subscription-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-terminal-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/terminal/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-terminal-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-transaction-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/transaction/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-transaction-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-transfer-control-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/transfer-control/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-transfer-control-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-transfer-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/transfer/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-transfer-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-transfer-recipient-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/transfer-recipient/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-transfer-recipient-index-mdx" */),
  "component---src-templates-api-ref-template-js-content-file-path-content-api-verification-index-mdx": () => import("./../../../src/templates/ApiRefTemplate.js?__contentFilePath=/opt/build/repo/content/api/verification/index.mdx" /* webpackChunkName: "component---src-templates-api-ref-template-js-content-file-path-content-api-verification-index-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-changelog-api-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/changelog/api.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-changelog-api-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-guides-accept-payments-on-your-android-app-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/guides/accept_payments_on_your_android_app.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-guides-accept-payments-on-your-android-app-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-guides-accept-payments-on-your-react-app-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/guides/accept_payments_on_your_react_app.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-guides-accept-payments-on-your-react-app-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-guides-accept-payments-on-your-vue-app-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/guides/accept_payments_on_your_vue_app.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-guides-accept-payments-on-your-vue-app-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-guides-index-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/guides/index.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-guides-index-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-guides-migrating-from-inlinejs-v-1-to-v-2-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/guides/migrating-from-inlinejs-v1-to-v2.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-guides-migrating-from-inlinejs-v-1-to-v-2-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-guides-using-the-paystack-checkout-in-a-mobile-webview-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/guides/using_the_paystack_checkout_in_a_mobile_webview.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-guides-using-the-paystack-checkout-in-a-mobile-webview-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-identity-verification-index-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/identity-verification/index.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-identity-verification-index-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-identity-verification-resolve-card-bin-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/identity-verification/resolve-card-bin.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-identity-verification-resolve-card-bin-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-identity-verification-validate-customer-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/identity-verification/validate-customer.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-identity-verification-validate-customer-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-identity-verification-verify-account-number-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/identity-verification/verify-account-number.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-identity-verification-verify-account-number-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-libraries-and-plugins-index-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/libraries-and-plugins/index.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-libraries-and-plugins-index-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-libraries-and-plugins-libraries-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/libraries-and-plugins/libraries.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-libraries-and-plugins-libraries-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-libraries-and-plugins-plugins-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/libraries-and-plugins/plugins.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-libraries-and-plugins-plugins-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-payments-accept-payments-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/payments/accept-payments.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-payments-accept-payments-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-payments-apple-pay-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/payments/apple-pay.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-payments-apple-pay-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-payments-charge-card-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/payments/charge-card.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-payments-charge-card-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-payments-dedicated-virtual-accounts-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/payments/dedicated-virtual-accounts.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-payments-dedicated-virtual-accounts-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-payments-index-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/payments/index.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-payments-index-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-payments-manage-disputes-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/payments/manage-disputes.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-payments-manage-disputes-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-payments-metadata-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/payments/metadata.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-payments-metadata-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-payments-multi-split-payments-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/payments/multi-split-payments.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-payments-multi-split-payments-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-payments-partial-debits-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/payments/partial-debits.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-payments-partial-debits-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-payments-payment-channels-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/payments/payment-channels.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-payments-payment-channels-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-payments-recurring-charges-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/payments/recurring-charges.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-payments-recurring-charges-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-payments-refunds-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/payments/refunds.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-payments-refunds-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-payments-split-payments-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/payments/split-payments.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-payments-split-payments-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-payments-subscriptions-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/payments/subscriptions.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-payments-subscriptions-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-payments-test-payments-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/payments/test-payments.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-payments-test-payments-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-payments-verify-payments-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/payments/verify-payments.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-payments-verify-payments-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-payments-webhooks-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/payments/webhooks.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-payments-webhooks-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-terminal-custom-apps-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/terminal/custom-apps.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-terminal-custom-apps-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-terminal-index-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/terminal/index.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-terminal-index-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-terminal-invoice-payments-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/terminal/invoice-payments.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-terminal-invoice-payments-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-terminal-push-payment-requests-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/terminal/push-payment-requests.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-terminal-push-payment-requests-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-transfers-bulk-transfers-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/transfers/bulk-transfers.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-transfers-bulk-transfers-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-transfers-creating-transfer-recipients-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/transfers/creating-transfer-recipients.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-transfers-creating-transfer-recipients-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-transfers-how-transfers-work-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/transfers/how-transfers-work.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-transfers-how-transfers-work-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-transfers-index-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/transfers/index.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-transfers-index-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-transfers-managing-transfers-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/transfers/managing-transfers.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-transfers-managing-transfers-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-content-doc-transfers-single-transfers-mdx": () => import("./../../../src/templates/DocTemplate.js?__contentFilePath=/opt/build/repo/content/doc/transfers/single-transfers.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-content-doc-transfers-single-transfers-mdx" */),
  "component---src-templates-error-detail-template-js": () => import("./../../../src/templates/ErrorDetailTemplate.js" /* webpackChunkName: "component---src-templates-error-detail-template-js" */),
  "component---src-templates-error-template-js": () => import("./../../../src/templates/ErrorTemplate.js" /* webpackChunkName: "component---src-templates-error-template-js" */)
}

